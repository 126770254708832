<template>
  <div class="main-contents display terms evl">
	  <div class="tit">평가항목 관리<img src="/images/admin/list_shortcut.png" alt="목록가기" @click="movePage('back')"/></div>
	  <div class="sub-tit"><p>하이프로/태도/책임감</p></div>
	  <div class="banner_box">
		  <div class="list">
			  <div class="label">평가자구분</div>
			  <SelectComp v-model="evalItem.evaluatorDivCd" cdId="PRO109" v-bind:disabled="evalItemSeq != ''"/>
		  </div>
		  <div class="list">
			  <div class="label">평가항목구분</div>
			  <SelectComp v-model="evalItem.evalItemDivCd" cdId="PRO110" v-bind:disabled="evalItemSeq != ''" />
		  </div>
		  <div class="list">
			  <div class="label">평가항목</div>
			  <SelectComp v-model="evalItem.evalItemCd" cdId="PRO111" v-bind:disabled="evalItemSeq != ''"/>
		  </div>
		  <div class="list matter note">
			  <div class="label">평가항목내용</div>
			  <textarea v-model="evalItem.evalItemCont"/>
		  </div>
		  <div class="select_btn">
			<div class="btn_01" @click="movePage('back')">취소</div>
			<div class="btn_02" style="background-color: #FF681D;" @click="updateEvalItem()">수정</div>
		</div>
	</div>
</div>
</template>

<script>
export default {
	data(){
		return{
			evalItem : {
				evalItemSeq : '',
				evaluatorDivCd : '',
				evalItemDivCd : '',
				evalItemCont : ''
			},

			evalItemSeq : this.$route.params.evalItemSeq || ''
		}
	},

	beforeMount(){
		this.getEvalItem();
	},

	// mounted(){
	// 	this.getEvalItem();
	// },

	methods : {
		getEvalItem(){
			var param = {};
			param.evalItemSeq = this.evalItemSeq;

			this.$.httpPost('/api/main/adm/eval/getEvalItem', {evalItemSeq : this.evalItemSeq})
				.then(res=>{
					this.evalItem = res.data.evalItem || {};
				}).catch(this.$.httpErrorCommon);
		},

		updateEvalItem(){
			var param = this.evalItem;
			this.$.popup('/adm/man/MAN935P01', param)
				.then(res=>{
					if(res){
						this.$.httpPost('/api/main/adm/eval/updateEvalItem', this.evalItem)
						.then(()=>{
							this.movePage();
						}).catch(this.$.httpErrorCommon);
					}
				})
		},

		movePage(div){
			if(div == 'back') this.$router.go(-1);
			else this.$router.push('MAN935M01');
		}
	}
}
</script>

<style>

</style>